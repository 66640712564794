.buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  > button {
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    > button {
      max-width: unset;
    }
  }
}
