@import '../../../../app//styles/variables.scss';
.button {
  display: flex;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  color: $color-white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 296px;
  width: 100%;
  height: 48px;
  cursor: pointer;
  background: $main-color-green;
  margin: 0 auto;
  &:hover {
    background: $dark-color-green;
    color: $color-white;
  }
  &:disabled {
    background: $inactive-background-stroke;
    color: $inactive-color-text;
    cursor: default;
    box-shadow: none;
  }
}
