.contact-us-body {
  display: flex;
  margin-bottom: 40px;
  &-text {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 20px;
  }

  > iframe {
    margin-left: auto;
  }
}
