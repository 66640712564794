.profile-info {
  margin-bottom: 20px;
  &-title {
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  &-list-container {
    display: flex;
    gap: 20px;
  }
  &-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px 0;
  }
  &-list-item {
    line-height: 1.5;
  }
  &-label {
    font-weight: 600;
  }
  &-data {
    margin-left: 5px;
    text-transform: capitalize;
  }
}
