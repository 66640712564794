@import '../../../app/styles/variables.scss';
.customTableRow {
  & .ant-table-cell {
    cursor: pointer;
  }
  &:hover {
    [class*='ant-table-cell-row-hover'] {
      background: $background-color !important;
    }
  }
}
