@import '../../../app/styles/variables.scss';
.invoice-info {
  &-title {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  &-company {
    margin-bottom: 20px;
  }
  &-list-container {
    display: flex;
    gap: 20px;
  }
  &-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px 0;
  }
  &-list-item {
    line-height: 1.5;
  }
  &-label {
    font-weight: 600;
  }
  &-data {
    margin-left: 5px;
    padding: 5px;
    border-radius: 4px;

    &-draft {
      background-color: $color-draft;
      color: #fff;
    }
    &-paused {
      background-color: $color-paused;
      color: #fff;
    }
    &-submitted {
      background-color: $color-submitted;
      color: #fff;
    }
    &-in_processing {
      background-color: $color-in-processing;
      color: #fff;
    }
    &-submitting_error {
      background-color: $color-submitting-error;
      color: #fff;
    }
    &-processed {
      background-color: $color-processed;
      color: #fff;
    }
  }
  &-buttons {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    > button {
      margin: 0;
    }
  }
}
