@import './variables.scss';

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.ml-12 {
  margin-left: 12px;
}

.w-100 {
  width: 100%;
}
.flex-05 {
  flex: 0.5 !important;
}
.flex-06 {
  flex: 0.6 !important;
}
.flex-04 {
  flex: 0.4 !important;
}

.lh-15 {
  line-height: 1.5;
}

.w-content {
  width: fit-content !important;
}

.extrabold-24 {
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
}
.text-bold {
  font-weight: 600;
}

.normal-16 {
  font-size: 16px;
  line-height: 24px;
}
.text-size-80 {
  font-size: 80px;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.flex-column-center {
  display: flex;
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.flex-row {
  display: flex;

  flex-direction: row !important;
}
.flex-column {
  display: flex;

  flex-direction: column !important;
}

.block {
  display: block;
}

.center-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spin-white {
  .ant-spin-dot-holder {
    color: #fff;
  }
}

.text-error {
  color: $color-error;
  margin-bottom: 0 !important;
}
.error-field {
  border-color: $color-error !important;
}

.logo-register {
  width: 130px;
  height: 130px;

  > svg {
    width: 100%;
    height: auto;
  }
}
.logo-app {
  display: flex;
  width: 50px;
  height: 48px;

  > svg {
    width: 100%;
    height: auto;
  }
}

.arrow-back {
  font-size: 24px;
  margin-bottom: 20px;
  cursor: pointer;
}

.color-submitted {
  color: $color-submitted;
}

.color-error {
  color: $color-error;
}
.icon-bold {
  font-size: 20px;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}
.ant-btn {
  border-radius: 0 !important;
}
