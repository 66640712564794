@import '../../../app/styles/variables.scss';
.navbar {
  &-container {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: $main-color-green;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1240px;
    padding: 0 40px;

    position: relative;
    margin: 0 auto;
    @media (width <= 768px) {
      padding: 0 20px;
    }
  }

  &-list {
    display: flex;
    justify-content: center;
    flex: 1;
    gap: 0 20px;

    & > a {
      color: #fff;
      text-decoration: none;
      transition: color 0.1s ease;

      &:hover {
        color: $light-color-green;
        transition: color 0.3s ease;
      }
      &.active {
        color: $light-color-green;
        transition: color 0.2s ease;
      }
    }
  }
}

.navigate-dropdown-list-item {
  padding: 5px 20px !important;
  font-size: 14px;
  text-decoration: none;
  &-logout {
    color: #f5704a;
  }
}
.avatar-icon {
  background-color: #fff;
  > span {
    svg {
      path {
        fill: $text-color;
      }
    }
  }
  &:hover {
    background-color: $light-color-green;
    > span {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
