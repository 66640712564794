@import '../../../app/styles/variables.scss';
.invoice-create-form {
  margin-top: 40px;
  max-width: 600px;
  width: 100%;
  &-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-select-company {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    max-width: 600px;
    width: 100%;

    &-title {
      margin: 0 0 15px 0;
    }
  }
  & &-block {
    padding: 40px 0;
  }
  &-block-title {
    width: 100%;
    margin-bottom: 15px;
  }
  &-title {
    margin-bottom: 15px;
    text-align: center;
  }
  &-name {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    & > span {
      font-size: 13px;
      font-weight: 500;
      color: $color-gray;
    }
  }

  &-item {
    margin-bottom: 10px;
    max-width: 400px;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    & > button {
      margin: unset !important;
    }
  }
}
