@import '../../../app/styles/variables.scss';
.factura-top-section {
  display: flex;
  flex-direction: column;
  //   width: 80%;
  text-align: justify;
  margin: 0 auto;
}
.factura-bottom-section {
  display: flex;
  gap: 60px;

  > div {
    flex: 1;
  }
}

.factura-icon {
  width: 60px;
  height: auto;
  overflow: hidden;
  margin: 0 auto 20px;
  > svg {
    width: 100%;
    height: auto;
    path {
      fill: $main-color-green;
    }
  }
}
