.form-filters {
  &-items {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &-buttons {
    margin-top: 16px;
    display: flex;
    justify-content: end;
    gap: 15px;
    margin-left: auto;

    > button {
      margin: 0;
      width: 100px;
      padding: 8px 15px;
      border-radius: 0;
      height: fit-content;
    }
  }
}

.form-item-companies {
  width: calc(100% / 2 - 10px);
  margin-bottom: 0;
  @media (max-width: 980px) {
    width: 100%;
  }

  &-lg {
    width: 100%;
  }
}

.form-item-date-created {
  @media (max-width: 980px) {
    width: 80%;
    max-width: unset;
  }
}

.form-item {
  width: calc(100% / 3 - 10px);
  margin-bottom: 0;
  &-sm {
    max-width: 300px;
    width: 100%;
    margin-bottom: 0;
  }
  &-sm.form-item-date-created {
    @media (max-width: 980px) {
      max-width: 300px;
      width: 100%;
    }
  }
}

.form-items-with-buttons {
  display: flex;
  width: calc(50% - 10px);
  align-items: end;
  gap: 15px;

  @media (max-width: 980px) {
    width: 100%;
  }

  &-lg {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    width: 100%;

    .form-filters-buttons {
      margin-top: 28px;
    }
  }
}
