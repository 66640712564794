@import '../../../../app/styles/variables.scss';
.footer {
  width: 100%;
  background: $dark-color-green;

  &-item {
    display: flex;
    max-width: 1240px;
    padding: 0 40px;
    width: 100%;
    margin: 0 auto;
  }

  &-logo {
    min-width: 85px;
    height: 85px;
    width: 85px;
    margin-top: 26px;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  &-text {
    padding: 32px 24px;
    color: $color-white;
    width: 734px;
    line-height: 1.5;
  }

  &-links {
    display: flex;
    flex-direction: column;
    padding: 32px 0 32px 0;
    min-width: 245px;
    gap: 16px;
    margin-left: auto;
    > a {
      color: $color-white;
      text-decoration: underline;

      &:hover {
        color: $light-color-green;
      }
    }
  }
}
