@import '../../../../app/styles/variables.scss';
.custom-link {
  font-size: $default-font-size;
  font-family: $main-font;
  color: $text-color;
  text-decoration: none;
  &:hover {
    color: $main-color-green;
    text-decoration: none;
  }
}
