@import '../../../../app/styles/variables.scss';

.input-mask-number {
  border-radius: 0 !important;
  height: 40px;
  width: 100%;
  box-shadow: unset;
  outline: none;
  border: 1px solid #d9d9d9;
  padding: 7px 11px;
  font-size: 16px;

  border-color: $input-border-color;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: $main-color-green;
  }
}
