@import '../../../app/styles/variables.scss';
.invoices-table {
  width: 100%;
}
.btn-invoices-add {
  position: sticky;
  bottom: 20px;
  margin: 20px 0 0 auto;
  width: 75px;
  height: 75px;
  padding: 10px 20px;
  border-radius: 0;
  font-size: 50px;
}

.table-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.sort-icons {
  display: flex;
  font-size: 12px;
  flex-direction: column;
}
.caret {
  width: 10px;
  height: 7px;
  &:hover {
    cursor: pointer;
  }
  path {
    fill: $inactive-color-text;
  }

  &-active {
    path {
      fill: $main-color-green;
    }
  }
}

.invoice-filter-block {
  margin-bottom: 20px;
  &-title {
    margin-bottom: 10px;
  }
}

.bulk-submit-top,
.bulk-submit-btm {
  width: 120px;
  padding: 8px 15px;
  border-radius: 0;
  height: fit-content;
}
.bulk-submit-top {
  margin: 0 0 20px auto;
}
.bulk-submit-btm {
  margin: 0 0 0 auto;
}
