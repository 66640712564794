@import '../../../app/styles/variables.scss';
.form-space {
  display: flex;
  flex-direction: column;

  & .ant-space-item {
    flex: 1;
  }
}
.form-flex {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 8px;
}
.fields-profile-wrapper {
  display: flex;
}

.form-read-only {
  margin-bottom: 24px;

  .custom-label {
    padding: 0 0 8px;
  }

  &:hover {
    cursor: not-allowed;
  }
}

.form-email {
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  background: $color-white;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0;
}

.buttons-wrapper-profile {
  flex-direction: row;
}
