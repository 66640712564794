@import './reset.scss';
@import './variables.scss';
@import './helper-classes.scss';
body {
  margin: 0;
  font-family: $main-font;
  font-weight: normal;
  font-size: $default-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  background-color: $background-color;
  height: 100vh;
  overflow: hidden;
}

#root {
  height: inherit;
}

.pageWrapper {
  flex-grow: 1;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}
.register-wrapper {
  height: 100vh;
  padding-top: 40px;
}

.pageContainer {
  // margin-top: 20px;
  // padding: 24px 0 0;
  padding: 40px 40px 0;

  height: auto;
  width: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (width <= 768px) {
    padding: 20px 20px 0;
  }
}

.pageContent {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 60px;
}

.header-text {
  margin: 16px 0 32px 0;
  margin-top: 20px;
}

.not-found-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ant-input,
.ant-input-lg,
.ant-input-affix-wrapper-lg,
.ant-picker-outlined {
  border-radius: 0 !important;
  border-color: $input-border-color;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: $main-color-green;
  }
}
.ant-picker-panel-container {
  border-radius: 0 !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background: $main-color-green;
}
.ant-picker-now {
  > a {
    color: $main-color-green;
  }
}
.ant-select-selector {
  border: 1px solid $input-border-color !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid $main-color-green !important;
  }
}
.ant-select-dropdown {
  padding: 4px 0 !important;
  border-radius: 0 !important;
  border: 1px solid $main-color-green !important;
}

.ant-select {
  &:hover,
  &:focus,
  &:focus-within {
    [class*='ant-select-arrow'] {
      color: $main-color-green !important;
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: $main-color-green !important;
  color: $color-white !important;
  border-radius: 0 !important;

  [class*='ant-select-item-option-state'] {
    color: $color-white !important;
  }
}
.ant-dropdown .ant-dropdown-menu {
  padding: 4px 0;
}
.ant-select-selection-overflow .ant-select-selection-item,
.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 0 !important;
}
.ant-table-selection-column {
  min-width: 45px !important;
}
.ant-table-wrapper {
  border: 1px solid $input-border-color;
}
.ant-table-wrapper .ant-table-tbody > tr > td > tr > td {
  border-bottom: 1px solid $input-border-color !important;
}
.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: #f5f5f5 !important;
}
.ant-checkbox-inner {
  background-color: #f5f5f5 !important;

  border-color: $inactive-color-text !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $main-color-green !important;
  border: none !important;
}
.ant-checkbox-inner:after {
  background-color: $main-color-green !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ffffff !important;
}

.ant-checkbox,
.ant-checkbox .ant-checkbox-inner {
  border-radius: 0 !important;
}
.ant-pagination {
  background: $color-white;
  margin: 0 !important;
  padding: 20px;
}
.ant-pagination .ant-pagination-item-active a {
  color: $main-color-green !important;
}
.ant-pagination .ant-pagination-item-active {
  border-color: $main-color-green !important;
  border-radius: 0 !important;
}

.ant-table-tbody {
  color: $text-color;
}

.ant-btn-primary {
  color: $color-white !important;
  background: $main-color-green !important;
  &:hover {
    background: $dark-color-green;
    color: $color-white;
  }
}
.ant-btn-default {
  &:hover {
    border-color: $dark-color-green !important;
    color: $main-color-green !important;
  }
}

.ant-form-item-explain-error {
  color: $color-error !important;
}

.ant-spin .ant-spin-dot-holder {
  color: $main-color-green !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $main-color-green !important;
  background-color: $main-color-green !important;
}
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: $main-color-green !important;
}
