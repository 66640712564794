$primary-color: #231b45;
$bg-color: #f7f5e7;
$link-color: #2f4a60;
$link-hover-color: #7da1bf;

$btn-background: #94553d;
$disabled-color: #c6c1d2;

$main-font: Montserrat, sans-serif;

$default-font-size: 16px;
$color-gray: #808080;

$color-draft: #b0b0b0;
$color-paused: #ff9800;
$color-submitted: #4caf50;
$color-in-processing: #2196f3;
$color-processed: #673ab7;

// New Colors schemes
$main-color-green: #02834e;
$light-color-green: #70cd9b;
$dark-color-green: #006338;
$text-color: #262626;
$inactive-color-text: #9d9d9d;
$inactive-background-stroke: #e9e9e9;
$background-color: #f5f5f5;
$input-border-color: #d9d9d9;
$color-white: #fff;
$color-error: #dc1732;
$color-submitting-error: $color-error;
