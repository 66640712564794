@import '../../../app/styles/variables.scss';

.onboarding {
  position: relative;
  &-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    > button {
      margin: 0;
    }
  }
}
.btn-onboarding-next {
  margin: 0;
  margin-left: auto;
  margin-bottom: 20px;
}
.btn-onboarding-skip {
  font-size: 14px;
  margin: 0 0 0 auto;
  color: $text-color;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  text-decoration: none;
  background: transparent;
  font-weight: 400;
  width: fit-content;

  &.button:disabled {
    background: transparent;
    pointer-events: none;
  }

  &:hover {
    color: $main-color-green;
    background: transparent;
  }
}
.img {
  &-profile,
  &-submit-all,
  &-status {
    width: 60%;
    height: auto;
    position: relative;
  }
  &-status {
    width: 70%;
  }
  &-submit-all {
    margin-top: 10px;
  }
  &-invoice {
    width: 45%;
    height: auto;
    position: relative;
    margin-top: -52px;
    margin-left: auto;
    margin-right: 100px;
  }
  &-add-invoice,
  &-download {
    width: 48%;
    height: auto;
    position: relative;
  }
  &-download {
    margin-left: auto;
    margin-top: -45px;
  }
  &-select-company {
    width: 40%;
    height: auto;
    position: relative;
    margin-top: -93px;
    margin-left: auto;
    margin-right: 100px;
  }

  > img {
    width: 100%;
    height: auto;
    border: 1px solid $input-border-color;
  }
}

.description {
  padding: 20px;
  position: absolute;
  font-weight: 400;
  z-index: 3;
  &-profile,
  &-add-invoice {
    top: 49px;
    right: -326px;
    width: 340px;
  }

  &-invoice {
    bottom: 25%;
    left: -330px;
    width: 400px;
  }

  &-select-company,
  &-download {
    bottom: 25%;
    left: -270px;
    width: 400px;
  }
  &-submit-all {
    bottom: 25%;
    right: -405px;
    width: 400px;
  }
  &-status {
    top: 49px;
    right: -326px;
    width: 340px;
  }
}
.icon-hand {
  width: 40px;
  height: auto;
  position: absolute;
  left: -30px;
  top: 8%;
  transform: rotate(60deg);
}
