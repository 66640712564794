@import '../../../app/styles/variables.scss';
.profile {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-bottom: 40px;
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > button {
      margin: 0 0 0 auto;
    }
  }
  &-field {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    flex: 1;
    gap: 8px;

    &-item {
      padding: 10px 15px;

      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      background: $color-white;
      border-radius: 0;
      font-weight: 500;
      width: 100%;
    }
    &-address {
      flex: unset;
      width: calc(100% / 2 - 8px);
    }
  }
  &-btn-blocks {
    display: flex;
    align-items: center;
  }
}
.flex-block-profile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}
.label {
  font-size: 14px;
  font-weight: 500;
}
.change-password-link {
  margin: 0 0 0 auto;
  font-size: 14px;
}
